<template>
  <div class="body_section">
    <profileHeader :showFilter="false" />
    <div class="body_content">
      <div class="main_content">
        <div class="user_details_header">
          <!-- <router-link  to="/users" class="backall"><b-icon icon="arrow-left" aria-hidden="true"></b-icon>Back</router-link> -->
          <!-- <figure><img src="@/assets/images/user.jpg"></figure> -->
          <figure v-if="userDetails.details">
            <img
              @error="getProfilePhoto($event, userDetails.details.gender)"
              :src="userDetails.details.photo"
            />
          </figure>
          <div v-if="userDetails.details" class="user_details">
            <label v-if="userDetails.details"
              >{{ userDetails.details.name
              }}<span>{{ userDetails.roleDetails.name }}</span></label
            >
            <ul>
              <li v-if="userDetails.details">
                <a
                  ><em><img src="@/assets/images/message1.svg"/></em
                  >{{ userDetails.details.email }}</a
                >
              </li>
              <li v-if="userDetails.details.phone">
                <a
                  ><em><img src="@/assets/images/phone.svg"/></em
                  >{{ userDetails.details.phoneCode }}
                  {{ userDetails.details.phone }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="user_details_cnt">
          <div class="clients">
            <template v-if="userDetails.roleId == 2">
              <div class="user_details_title">
                <label
                  ><span><img src="@/assets/images/user.svg"/></span>Assign
                  Clients</label
                >
              </div>
              <div class="client_search">
                <vue-autosuggest
                  :suggestions="clientList"
                  @input="onInputChange"
                  @selected="onSelected"
                  :limit="10"
                  :liClass="'suggectli'"
                  v-model="searchText"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Search Client',
                  }"
                >
                </vue-autosuggest>
              </div>
              <template v-if="assignedList && assignedList.length > 0">
                <ul>
                  <li v-for="(item, index) in assignedList" :key="index">
                    <figure>
                      <label
                        ><img
                          @error="setAltImg($event)"
                          :src="checkProperty(item, 'logo')"
                      /></label>
                      <figcaption>{{ item.name }}</figcaption>
                    </figure>
                    <span @click="openDismiss(item)"
                      ><img src="@/assets/images/cross.svg"
                    /></span>
                  </li>
                </ul>
              </template>
            </template>
          </div>

          <div class="user_notes">
            <div class="user_details_title">
              <label
                ><span><img src="@/assets/images/note_icon.svg"/></span
                >Notes</label
              >
              <!--<a href="notes.html">View All</a>-->
              <router-link
                v-if="notesList && notesList.length > 0"
                :to="'/notes'"
                >View All</router-link
              >
            </div>
            <template v-if="notesList && notesList.length > 0">
              <ul>
                <li v-for="(item, index) in notesList" :key="index">
                  <h6>{{ item.note }}</h6>
                  <p>{{ item.description }}</p>
                  <span> {{ item.createdOn | formatDate }}</span>
                </li>
              </ul>
            </template>
            <template v-else>
              <span class="no_data">No Data Available!</span></template
            >
          </div>
        </div>

        <b-modal
          ref="assign-modal"
          centered
          hide-footer
          hide-header
          class="modal_popup"
        >
          <div class="modal_popup profile_popup">
            <b-button
              class="close"
              variant="outline-danger"
              block
              @click="hideModal('assign-modal')"
              ><span aria-hidden="true">&times;</span></b-button
            >
            <div class="alert_box">
              <label class="alert_msg"
                >Are you sure want to Assign this Client ?</label
              >
              <span class="primary_btn" @click="onAssignClient()">Assign</span>
            </div>
          </div>
        </b-modal>

        <b-modal
          ref="dismiss-modal"
          centered
          hide-footer
          hide-header
          class="modal_popup"
        >
          <div class="modal_popup profile_popup">
            <b-button
              class="close"
              variant="outline-danger"
              block
              @click="hideModal('dismiss-modal')"
              ><span aria-hidden="true">&times;</span></b-button
            >
            <div class="alert_box">
              <label class="alert_msg"
                >Are you sure of canceling this client assignment?</label
              >
              <div class="d-flex align-items-center">
                <span class="secondary_btn" @click="hideModal('dismiss-modal')"
                  >No</span
                >
                <span class="primary_btn" @click="onRemoveClient()">Yes</span>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
//import Multiselect from "vue-multiselect-inv";
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";
import { VueAutosuggest } from "vue-autosuggest";
export default {
  components: {
    VueAutosuggest,
    // Multiselect
    profileHeader,
    footerView,
  },
  data() {
    return {
      userdata: null,
      userId: "",
      clientId: "",
      mapId: "",
      userDetails: {},
      assignedList: [],
      clientList: [],
      originalCliList: [],
      totalPages: "",
      sortKey: {},
      sele: "",
      page: 1,
      perPage: 10,
      searchText: null,
      notesList: [],
    };
  },
  computed: {},
  methods: {
    openDismiss(item) {
      // alert(JSON.stringify(item));
      if (_.has(item, "_id")) {
        this.mapId = item._id;
        this.clientId = item.clientId;
      }
      this.$refs["dismiss-modal"].show();
    },
    onInputChange(val) {
      if (val === "" || val === null || val.length == 1) {
        this.originalCliList = [];
        this.clientList = [];
      }
      if (this.searchText.length > 1) {
        this.getCliList();
      }
    },
    onSelected(val) {
      var el = this.originalCliList.filter(
        (el) => el.name.toLowerCase() === val.item.toLowerCase()
      );
      var elObj = el[0];
      if (_.has(elObj, "_id")) {
        this.clientId = elObj._id;
      }
      this.onAssignClient();
      //this.$refs['assign-modal'].show();
    },
    hideModal(modal) {
      this.clientId = "";
      this.$refs[modal].hide();
    },
    getDetails() {
      const payload = {
        userId: this.userId,
      };
      this.$store
        .dispatch("getUserDetails", payload)
        .then((res) => {
          this.userDetails = res;
          if (this.userDetails.assignedClients) {
            this.assignedList = this.userDetails.assignedClients;
          }
          // this.getCliList();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    onAssignClient() {
      if (this.clientId) {
        let payLoad = {
          userId: this.userId,
          clients: [
            {
              clientId: this.clientId,
              status: true,
            },
          ],
        };
        this.$store
          .dispatch("onAssignClient", payLoad)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.originalCliList = [];
            this.clientList = [];
            this.searchText = null;
            this.hideModal("assign-modal");
            this.getDetails();
          })
          .catch((err) => {
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    onRemoveClient() {
      if (this.clientId) {
        let payLoad = {
          userId: this.userId,
          clients: [
            {
              _id: this.mapId,
              clientId: this.clientId,
              status: false,
            },
          ],
        };
        this.$store
          .dispatch("onAssignClient", payLoad)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.hideModal("dismiss-modal");
            this.getDetails();
          })
          .catch((err) => {
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    getCliList() {
      let postData = {
        filters: {
          title: this.searchText,
        },
        getAssignList: true,
        userId: this.userId,
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      this.clientList = [];
      this.originalCliList = [];
      this.$store
        .dispatch("getClientList", postData)
        .then((response) => {
          var userclientIds = _.map(this.assignedList, "clientId");
          this.originalCliList = _.filter(response.list, function(client) {
            return userclientIds.indexOf(client._id) <= -1;
          });
          var cliList = this.originalCliList.map((item) => item.name);
          if (cliList.length > 0) {
            this.clientList = [
              {
                data: cliList,
              },
            ];
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getNoteList() {
      let postData = {
        filters: {},
        sorting: { path: "createdOn", order: -1 }, //{"path": "name","order": -1	},
        page: 1,
        perpage: 4,
        getMyNotes: true,
        userId: this.userId,
      };
      this.notesList = [];
      this.$store
        .dispatch("getNotesList", postData)
        .then((response) => {
          this.notesList = response.list;
        })
        .catch((err) => {
          this.log(err);
        });
    },
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.userId = this.$route.params.id;
      this.clientId = "";
      this.sortKey = { path: "createdOn", order: 1 };
      this.getDetails();
      this.getNoteList();
    }
  },
};
</script>
